import cookie from './cookie.svg'
import coverPicture from './cover_image_resized.png'
import licence_comp from './licence_comp.png'
import logoBVA from './logo_bva.png'
import photo_groupe_bva_2024_2025 from './photo_groupe_bva_2024_2025.jpg'
import reunion from './reunion.jpg'

const images = {
    logoBVA,
    coverPicture,
    cookie,
    reunion,
    licence_comp,
    photo_groupe_bva_2024_2025,
}

export default images
