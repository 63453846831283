import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'
import { ReactNode, useContext, useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { default as ReactGA, default as ReactGA4 } from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import images from '../../assets/img/images'
import UserContext from '../../context/UserContext'
import AdminDashboard from '../../pages/admin/AdminDashboard'
import Login from '../../pages/admin/Login'
import AdminChatBot from '../../pages/admin/chatBot/AdminChatBot'
import AdminChatBotForm from '../../pages/admin/chatBot/AdminChatBotForm'
import AdminCoach from '../../pages/admin/coach/AdminCoach'
import AdminCoachForm from '../../pages/admin/coach/AdminCoachForm'
import AdminCompetitionForm from '../../pages/admin/competitions/AdminCompetitionForm'
import AdminCompetitions from '../../pages/admin/competitions/AdminCompetitions'
import AdminDocumentation from '../../pages/admin/documentation/AdminDocumentation'
import AdminDocumentationForm from '../../pages/admin/documentation/AdminDocumentationForm'
import AdminToDocumentMyself from '../../pages/admin/documentation/AdminToDocumentMyself'
import AdminNews from '../../pages/admin/news/AdminNews'
import AdminNewsForm from '../../pages/admin/news/AdminNewsForm'
import AdminPlannings from '../../pages/admin/plannings/AdminPlannings'
import AdminPlanningsForm from '../../pages/admin/plannings/AdminPlanningsForm'
import AdminPriceForm from '../../pages/admin/prices/AdminPriceForm'
import AdminPrices from '../../pages/admin/prices/AdminPrices'
import AdminUser from '../../pages/admin/users/AdminUsers'
import AdminUserForm from '../../pages/admin/users/AdminUsersForm'
import Page404 from '../../pages/global/404'
import Competitions from '../../pages/global/Competitions'
import Contact from '../../pages/global/Contact'
import Home from '../../pages/global/Home'
import PlanningPage from '../../pages/global/PlanningPage'
import Privacy from '../../pages/global/Privacy'
import MemberDashboard from '../../pages/member/MemberDashboard'
import MemberDocumentation from '../../pages/member/MemberDocumentation'
import MemberEditPassword from '../../pages/member/MemberEditPassword'
import UserInterface from './user-interface/UserInterface'

const CookiesComponent = (props: ButtonCookieProps) => <CookiesButton {...props}>{props.text}</CookiesButton>

const Router = () => {
    const user = useContext(UserContext)
    const [allCookies, setAllCookies] = useState(Cookies.get('all_cookies') === 'true')

    let lng =
        window.location.pathname
            .split('/')
            .filter((x, i) => i === 1)
            .join('/') || 'fr'

    if (
        !window.location.pathname
            .split('/')
            .filter((x, i) => i === 1)
            .join('/') &&
        !lng
    ) {
        lng = 'fr'
        window.location.replace('/fr')
    }

    const checkAuthentificationAdmin = (element: ReactNode, data: any) => {
        if (data) return element
        return <Navigate to={`/${lng}/bva-admin/login`} />
    }

    const checkAuthentificationMember = (element: ReactNode, data: any) => {
        if (data) return element
        return <Navigate to={`/${lng}/bva-membre/login`} />
    }

    const { t } = useTranslation('translation')

    useEffect(() => {
        if (allCookies) {
            ReactGA4.initialize('G-FVZ622CSL8', {
                gaOptions: { cookieDomain: 'auto', cookieExpires: 86400 },
            })

            ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
        }
    })

    return (
        <BrowserRouter>
            <CookieConsent
                enableDeclineButton
                location="bottom"
                ButtonComponent={CookiesComponent}
                customButtonProps={{ style: { backgroundColor: 'green' }, text: 'Tout accepter' }}
                customDeclineButtonProps={{ style: { backgroundColor: 'red' }, text: 'Tout refuser' }}
                cookieName="all_cookies"
                style={{ background: '#2B373B' }}
                expires={1}
                onAccept={() => setAllCookies(true)}
                onDecline={() => setAllCookies(false)}
            >
                <CookieImg src={images.cookie} alt="cookie image" /> {t('cookies')}
            </CookieConsent>
            <UserInterface>
                <Routes>
                    <Route path="/:lng" element={<Home />} />
                    <Route path="/:lng/privacy" element={<Privacy />} />
                    <Route path="/:lng/contact" element={<Contact />} />
                    <Route path="/:lng/competitions" element={<Competitions />} />
                    <Route path="/:lng/horaires-et-tarifs" element={<PlanningPage />} />
                    <Route
                        path="/:lng/bva-admin"
                        element={checkAuthentificationAdmin(<AdminDashboard />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/utilisateurs"
                        element={checkAuthentificationAdmin(<AdminUser />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/utilisateurs/creer"
                        element={checkAuthentificationAdmin(<AdminUserForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/utilisateurs/:id"
                        element={checkAuthentificationAdmin(<AdminUserForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/articles"
                        element={checkAuthentificationAdmin(<AdminNews />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/articles/creer"
                        element={checkAuthentificationAdmin(<AdminNewsForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/articles/:id"
                        element={checkAuthentificationAdmin(<AdminNewsForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/competitions"
                        element={checkAuthentificationAdmin(<AdminCompetitions />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/competitions/creer"
                        element={checkAuthentificationAdmin(<AdminCompetitionForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/competitions/:id"
                        element={checkAuthentificationAdmin(<AdminCompetitionForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/plannings"
                        element={checkAuthentificationAdmin(<AdminPlannings />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/plannings/creer"
                        element={checkAuthentificationAdmin(<AdminPlanningsForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/plannings/:id"
                        element={checkAuthentificationAdmin(<AdminPlanningsForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/tarifs"
                        element={checkAuthentificationAdmin(<AdminPrices />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/tarifs/:id"
                        element={checkAuthentificationAdmin(<AdminPriceForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/tarifs/creer"
                        element={checkAuthentificationAdmin(<AdminPriceForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/documentation"
                        element={checkAuthentificationAdmin(<AdminDocumentation />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/documentation/:id"
                        element={checkAuthentificationAdmin(<AdminDocumentationForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/documentation/creer"
                        element={checkAuthentificationAdmin(<AdminDocumentationForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/me-documenter"
                        element={checkAuthentificationAdmin(<AdminToDocumentMyself />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/coachs"
                        element={checkAuthentificationAdmin(<AdminCoach />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/coachs/:id"
                        element={checkAuthentificationAdmin(<AdminCoachForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/coachs/creer"
                        element={checkAuthentificationAdmin(<AdminCoachForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/bva-bot"
                        element={checkAuthentificationAdmin(<AdminChatBot />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/bva-bot/creer"
                        element={checkAuthentificationAdmin(<AdminChatBotForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/bva-bot/:id"
                        element={checkAuthentificationAdmin(<AdminChatBotForm />, user.adminData)}
                    />
                    <Route
                        path="/:lng/bva-admin/login"
                        element={user.adminData ? <Navigate to={`/${lng}/bva-admin`} /> : <Login />}
                    />
                    <Route
                        path="/:lng/bva-admin/*"
                        element={user.adminData ? <Navigate to={`/${lng}/bva-admin`} /> : <Login />}
                    />
                    <Route
                        path="/:lng/bva-membre"
                        element={checkAuthentificationMember(<MemberDashboard />, user.memberData)}
                    />
                    <Route
                        path="/:lng/bva-membre/documentation"
                        element={checkAuthentificationMember(<MemberDocumentation />, user.memberData)}
                    />
                    <Route
                        path="/:lng/bva-membre/modifier-mon-mot-de-passe"
                        element={checkAuthentificationMember(<MemberEditPassword />, user.memberData)}
                    />
                    <Route
                        path="/:lng/bva-membre/login"
                        element={user.memberData ? <Navigate to={`/${lng}/bva-membre`} /> : <Login />}
                    />
                    <Route
                        path="/:lng/bva-membre/*"
                        element={user.memberData ? <Navigate to={`/${lng}/bva-membre`} /> : <Login />}
                    />
                    <Route path="/:lng/*" element={<Page404 />}></Route>
                </Routes>
            </UserInterface>
            {/* <ChatBot /> */}
        </BrowserRouter>
    )
}

const cookieAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const CookieImg = styled.img`
    width: 60px;
    animation: ${cookieAnimation} 10s linear infinite;
`

const CookiesButton = styled.button`
    border: none;
    webkit-appearance: none;
    margin-right: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    padding: 8px 16px;
    border-radius: 32px;
    color: white;

    &:active {
        transform: scale(0.95);
    }
`

type ButtonCookieProps = {
    text: string
    style: {
        backgroundColor: string
    }
}

export default Router
