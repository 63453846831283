import { Form } from 'react-bootstrap'
import Label from '../../../../components/form/Label'

type PasswordFieldProps = {
    isAddForm: boolean
    lastPassword: string
    setLastPassword: (value: string) => void
    password: string
    setPassword: (value: string) => void
    confirmPassword: string
    setConfirmPassword: (value: string) => void
}

const PasswordForm = ({
    isAddForm,
    lastPassword,
    setLastPassword,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
}: PasswordFieldProps) => {
    return (
        <>
            {!isAddForm && (
                <Form.Group className="mb-3">
                    <Label>Mot de passe actuel {isAddForm && '*'}</Label>
                    <Form.Control
                        type="password"
                        placeholder="Mot de passe actuel"
                        onChange={(e) => setLastPassword(e.target.value)}
                        value={lastPassword}
                    />
                </Form.Group>
            )}
            <Form.Group className="mb-3">
                <Label>Nouveau mot de passe {isAddForm && '*'}</Label>
                <Form.Control
                    type="password"
                    placeholder="Nouveau mot de passe"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Label>Confirmer le mot de passe {isAddForm && '*'}</Label>
                <Form.Control
                    type="password"
                    placeholder="Confirmer le mot de passe"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                />
            </Form.Group>
        </>
    )
}

export default PasswordForm
