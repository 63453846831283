import styled from '@emotion/styled'
import axios from 'axios'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Label from '../../../components/form/Label'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { roles } from '../../../types/user.type'
import { StyledButton } from '../Login'
import { AdminContainer, TitleWrapper } from './AdminUsers'
import MemberField from './components/MemberField'
import PasswordForm from './components/PasswordForm'

const AdminUserForm = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const isAddForm = location.pathname.includes(`/${lng}/bva-admin/utilisateurs/creer`)

    const [pseudo, setPseudo] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('placeholder')
    const [lastPassword, setLastPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [licenseNumber, setLicenseNumber] = useState('')
    const [tel, setTel] = useState('')
    const [dayOfBirth, setDayOfBirth] = useState<string | null>(null)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [gender, setGender] = useState('placeholder')
    const [tournamentMoney, setTournamentMoney] = useState(isAddForm ? '0' : '')

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        verifyToken()
        if (!isAddForm && isLoading) {
            axios
                .get(`${SERVER_URL}/users/${params.id}`, apiHeader(user.tokenAdmin))
                .then((res) => {
                    setPseudo(res.data.pseudo)
                    setEmail(res.data.email)
                    setRole(res.data.role)
                    setFirstname(res.data.firstName)
                    setLastname(res.data.lastName)
                    setGender(res.data.gender)
                    setLicenseNumber(res.data.licenseNumber)
                    setTel(res.data.tel)
                    if (res.data.dayOfBirth) setDayOfBirth(dayjs(res.data.dayOfBirth).add(-1, 'day').format())
                    setTournamentMoney(res.data.tournamentMoney)
                    setIsLoading(false)
                })
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            setIsLoading(false)
        }
    }, [isAddForm, isLoading, user.tokenAdmin, params.id])

    if (isLoading) return <Loader />

    const isPseudoValidate = pseudo.length > 2
    const isEmailValidate = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
    const isPasswordValidate = isAddForm ? password.length > 3 : true
    const isConfirmPasswordValidate = isAddForm ? confirmPassword.length > 3 : true
    const isLastPasswordValidate = isAddForm ? lastPassword.length > 3 : true
    const isRoleValidate = role !== 'placeholder'
    const isFirstnameValidate = !!firstname
    const isLastnameValidate = !!lastname
    const isGenderValidate = gender !== 'placeholder'
    const isLicenseNumberValidate = !!licenseNumber
    const isTournamentMoneyValidate = tournamentMoney !== '' && Number.isInteger(+tournamentMoney)

    const isValidate = () => {
        if (isAddForm) {
            if (role === roles.ROLE_ADMIN)
                return (
                    isPseudoValidate &&
                    isRoleValidate &&
                    isEmailValidate &&
                    isPasswordValidate &&
                    isConfirmPasswordValidate &&
                    password === confirmPassword
                )
            else {
                return (
                    isEmailValidate &&
                    isRoleValidate &&
                    isFirstnameValidate &&
                    isLastnameValidate &&
                    isGenderValidate &&
                    isTournamentMoneyValidate &&
                    isLicenseNumberValidate &&
                    isPasswordValidate &&
                    isConfirmPasswordValidate &&
                    password === confirmPassword
                )
            }
        }

        if (role === roles.ROLE_ADMIN)
            return (
                isRoleValidate &&
                isEmailValidate &&
                isPasswordValidate &&
                isConfirmPasswordValidate &&
                password === confirmPassword
            )

        return (
            isPseudoValidate &&
            isEmailValidate &&
            isRoleValidate &&
            isFirstnameValidate &&
            isLastnameValidate &&
            isGenderValidate &&
            isTournamentMoneyValidate &&
            isLicenseNumberValidate &&
            ((isPasswordValidate && isLastPasswordValidate) || (!isPasswordValidate && !isLastPasswordValidate))
        )
    }

    const submit = () => {
        verifyToken()
        if (isAddForm) {
            let data
            if (role === roles.ROLE_ADMIN) data = { pseudo, email, password, role }
            else {
                data = {
                    pseudo: `${firstname}-${licenseNumber}`,
                    email,
                    password,
                    role,
                    firstName: firstname,
                    lastName: lastname,
                    dayOfBirth: dayjs(dayOfBirth).add(1, 'day').format(),
                    gender,
                    licenseNumber,
                    tel,
                }
            }

            axios
                .post(`${SERVER_URL}/users/register`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`L'utilisateur a bien été créé.`).then(() => {
                        navigate(`/${lng}/bva-admin/utilisateurs`)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            let data
            if (role === roles.ROLE_ADMIN) data = { pseudo, email, lastPassword, password, role }
            else {
                data = {
                    pseudo: `${firstname}-${licenseNumber}`,
                    email,
                    lastPassword,
                    password,
                    role,
                    firstName: firstname,
                    lastName: lastname,
                    dayOfBirth: dayjs(dayOfBirth).add(1, 'day').format(),
                    gender,
                    licenseNumber,
                    tel,
                    tournamentMoney,
                }
            }

            axios
                .patch(`${SERVER_URL}/users/${params.id}`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`L'utilisateur a bien été modifié.`).then(() => {
                        navigate(`/${lng}/bva-admin/utilisateurs`)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        }
    }

    return (
        <>
            <TitleWrapper>
                <Title>{isAddForm ? 'Créer' : 'Modifier'} un utilisateur</Title>
                <StyledButtonLink to={`/${lng}/bva-admin/utilisateurs`} className="btn btn-primary mb-2">
                    Retour
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Role *</Label>
                        <Form.Select defaultValue={role} onChange={(e) => setRole(e.target.value)}>
                            <option value="placeholder" disabled>
                                Sélectionne le role
                            </option>
                            <option value={roles.ROLE_ADMIN}>Administrateur</option>
                            <option value={roles.ROLE_MEMBER}>Adhérent</option>
                        </Form.Select>
                    </Form.Group>

                    {role !== 'placeholder' && (
                        <>
                            <Form.Group className="mb-3">
                                <Label>Email *</Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </Form.Group>
                            {role === roles.ROLE_ADMIN && (
                                <Form.Group className="mb-3">
                                    <Label>Nom d'utilisateur *</Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Pseudo"
                                        onChange={(e) => setPseudo(e.target.value)}
                                        value={pseudo}
                                    />
                                </Form.Group>
                            )}
                        </>
                    )}

                    {role === roles.ROLE_MEMBER && (
                        <MemberField
                            isAddForm={isAddForm}
                            dayOfBirth={dayOfBirth}
                            setDayOfBirth={setDayOfBirth}
                            firstname={firstname}
                            setFirstname={setFirstname}
                            gender={gender}
                            setGender={setGender}
                            lastname={lastname}
                            setLastname={setLastname}
                            licenseNumber={licenseNumber}
                            setLicenseNumber={setLicenseNumber}
                            tel={tel}
                            setTel={setTel}
                            tournamentMoney={tournamentMoney}
                            setTournamentMoney={setTournamentMoney}
                        />
                    )}

                    {role !== 'placeholder' && (
                        <PasswordForm
                            isAddForm={isAddForm}
                            confirmPassword={confirmPassword}
                            lastPassword={lastPassword}
                            password={password}
                            setConfirmPassword={setConfirmPassword}
                            setLastPassword={setLastPassword}
                            setPassword={setPassword}
                        />
                    )}

                    <StyledButton variant="primary" type="button" disabled={!isValidate()} onClick={submit}>
                        {isAddForm ? 'Créer' : 'Modifier'}
                    </StyledButton>
                </AdminForm>
            </AdminContainer>
        </>
    )
}

export const AdminForm = styled(Form)`
    width: 100%;

    input,
    select {
        font-size: 1.6rem;
    }
`

export default AdminUserForm
