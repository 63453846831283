import styled from '@emotion/styled'
import {
    faBook,
    faCalendarDays,
    faDumbbell,
    faEuro,
    faHomeLg,
    faMedal,
    faNewspaper,
    faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Breakpoint from '../../../Breakpoint'
import { useLangSelected } from '../../../hooks/useLangSelected'
import AdminContainer from '../../admin/AdminContainer'
import MainLink from '../MainLink'

export const AdminSideBar = ({ children }: { children: ReactNode }) => {
    const location = useLocation()

    let lng = useLangSelected()

    const { t } = useTranslation()

    return (
        <>
            <Sidebar>
                <SidebarLink
                    to={`/${lng}/bva-admin`}
                    className={location.pathname === `/${lng}/bva-admin` ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faHomeLg} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.dashboard')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-admin/utilisateurs`}
                    className={location.pathname.includes(`/${lng}/bva-admin/utilisateurs`) ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faUser} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.user')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-admin/articles`}
                    className={location.pathname.includes(`/${lng}/bva-admin/articles`) ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faNewspaper} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.news')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-admin/competitions`}
                    className={location.pathname.includes(`/${lng}/bva-admin/competitions`) ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faMedal} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.competition')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-admin/plannings`}
                    className={location.pathname.includes(`/${lng}/bva-admin/plannings`) ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faCalendarDays} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.plannings')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-admin/tarifs`}
                    className={location.pathname.includes(`/${lng}/bva-admin/tarifs`) ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faEuro} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.prices')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-admin/documentation`}
                    className={location.pathname.includes(`/${lng}/bva-admin/documentation`) ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faBook} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.documentation')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-admin/me-documenter`}
                    className={location.pathname.includes(`/${lng}/bva-admin/me-documenter`) ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faBook} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.to-document-myself')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-admin/coachs`}
                    className={location.pathname.includes(`/${lng}/bva-admin/coachs`) ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faDumbbell} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.coach')}
                </SidebarLink>
                {/* <SidebarLink
                                to={`/${lng}/bva-admin/bva-bot`}
                                className={location.pathname.includes(`/${lng}/bva-admin/bva-bot`) ? 'active' : ''}
                            >
                                <FontAwesomeIcon icon={faRobot} />
                                {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.bva-bot')}
                            </SidebarLink> */}
            </Sidebar>
            <AdminContainer>{children}</AdminContainer>
        </>
    )
}

const SidebarLink = styled(MainLink)`
    display: flex;
    align-items: center;

    &.active {
        border: none;
    }

    & * {
        margin-right: 8px;

        @media (max-width: ${Breakpoint.TABLET_L}) {
            justify-content: center;
            margin-right: 0;
        }
    }

    &:active {
        transform: scale(0.95);
    }

    @media (max-width: ${Breakpoint.TABLET_L}) {
        justify-content: center;
    }
`

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    width: 15%;
    background-color: white;
    min-height: calc(100vh - 166px);
    box-shadow: 0px 0px 4px black;
    box-sizing: border-box;
    padding: 16px;
    padding-left: 3%;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        padding: 24px 0;
    }
`

export default AdminSideBar
